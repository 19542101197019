import { BASE_LOCALE } from "~/utilities/constants/LOCALES";
import { useI18n } from "vue-i18n";

export const formatDate = (value: number) => {
  if (!value) return "-";

  const date = new Date(value);

  return new Intl.DateTimeFormat(BASE_LOCALE, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const formatChartDate = (value: number) => {
  if (!value) return "-";

  const date = new Date(value);

  return new Intl.DateTimeFormat(BASE_LOCALE, {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(date);
};

export const formatDateToWords = (
  dateString: string | null | undefined
): string => {
  if (!dateString) return "";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = dateString.split("-");
  const year = dateParts[0] || "";
  const month = dateParts[1]?.includes("Q")
    ? dateParts[1]
    : months[parseInt(dateParts[1], 10) - 1] || "";
  const day = parseInt(dateParts[2], 10) || "";

  return `${month} ${day}${month || day ? "," : ""} ${year}`;
};

export const getRelativeTime = (dateString: string): string => {
  if (!dateString) return "";
  const { t } = useI18n();
  const date = new Date(dateString);
  const now = new Date();

  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;

  if (diffInSeconds < secondsInMinute) {
    return t("relativeTime.secondsAgo", { count: diffInSeconds });
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return t("relativeTime.minutesAgo", { count: minutes });
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return t("relativeTime.hoursAgo", { count: hours });
  } else if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return t("relativeTime.daysAgo", { count: days });
  } else {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    return t("relativeTime.weeksAgo", { count: weeks });
  }
};
